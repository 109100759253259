<template>
    <div class="deliveries-report-page">
        <div class="container-fluid">
            <h1>Dostawy</h1>
            <b-button class="mr-2 mb-2 d-print-none" variant="success" v-on:click="createNew()">Dodaj nowe dostawy do opłaconych zamówień</b-button>            
            <b-button-group class="mr-2 mb-2 d-print-none">            
                <b-button variant="secondary" :to="{name: 'adminDeliveriesList',  params: { day: (currentDay.format('YYYY-MM-DD')) } }">Dostawy {{ currentDay.format('DD.MM') }}</b-button>            
                <b-button variant="secondary" :to="{name: 'adminDeliveriesList',  params: { day: (nextDay.format('YYYY-MM-DD')) } }">Dostawy {{ nextDay.format('DD.MM') }}</b-button>
            </b-button-group>
            <div class="d-flex justify-content-between mt-3">
                <div class="pr-2 d-print-none">
                    <b-button class="btn mr-2 mb-2" :variant="selectedNew ? 'primary' : 'secondary'"  @click.prevent="setSelectedStatusNew()">Nowe</b-button>
                    <b-button class="btn mr-2 mb-2" :variant="selectedDelivered ? 'primary': 'secondary'" @click.prevent="setSelectedStatusDelivered()">Dostarczone</b-button>
                    <b-button class="btn mr-2 mb-2" :variant="selectedUndelivered ? 'primary': 'secondary'" @click.prevent="setSelectedStatusUndelivered()">Niedostarczone</b-button>
                </div>
                <!-- <div class="pr-2 flex-grow-1">
                    <input type="text" class="form-control" v-model="filter.search" placeholder="Szukaj" />
                </div> -->
                <b-pagination v-if="totalRows>perPage" 
                    v-model="currentPage"
                    :totalRows="totalRows"
                    :per-page="perPage"
                ></b-pagination>
            </div>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
            <b-table v-else :items="getDeliveries" :fields="fields" :filter="filter" :show-empty="true" ref="table"
                    class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                    :current-page="currentPage" :per-page="perPage"
                    sort-by="id"
                    :sort-desc="true"
                >

                <template #cell(options)="data">
                    <b-button v-if="data.item.status!=2" variant="warning" v-on:click="goToSMS(data.item.address.phone,'Uprzejmie informujemy że zamówienie nr: ' + data.item.schedule_id + ' zostało właśnie dostarczone. Dziękujemy i życzymy smacznego');" class="m-1 d-print-none">SMS</b-button>
                    <b-button v-if="data.item.status!=2" variant="success" v-on:click="goDeliveryConfirm(data.item.id)" class="m-1 d-print-none">Doręczono</b-button>
                    <b-button variant="secondary" :to="{name: 'AdminDeliveryReport',  params: { deliveryId: data.item.id } }" class="m-1 d-print-none">Szczegóły</b-button>
                    <b-button v-if="data.item.status!=2" variant="warning" v-on:click="goToCall(data.item.address.phone);" class="m-1 d-print-none">Połącz</b-button>
                </template>
                
            </b-table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';
import moment from 'moment';

import { DELIVERY_statusNew, DELIVERY_statusDelivered, DELIVERY_statusUndelivered  } from "@/constants/constants.js";

export default {
    name: 'adminDeliveries',
    data() {
        return {
            isLoading: false,
            filter: {
                status: DELIVERY_statusNew
            },
            perPage: 25,
            currentPage: 1,
            totalRows: 0,
            fields: [
                { label: 'Dostawa', key: 'id', sortable: true },
                { label: 'Data', key: 'date', sortable: true },
                // { label: 'Kierowca', key: 'driver.fullname', sortable: true },
                { label: 'Zamówienie', key: 'schedule.id', sortable: true },
                { label: 'Odbiorca', key: 'address.fullname', sortable: true },
                { label: 'Adres', key: 'address.fulladdresswithcomment', sortable: true },
                { label: 'Status ', key: 'statusname', sortable: false },
                // { label: 'Komentarz', key: 'comments', sortable: false },
                { label: 'Produkty', key: 'productslist', sortable: false },
                { label: '', key: 'options', sortable: false }
            ],
            currentDay: moment(new Date()),
            nextDay: moment(new Date()).add(1,'day'),
            deliveries: null,
        }
    },
    computed: {
        selectedNew() {
            return this.filter.status == DELIVERY_statusNew;
        },
        selectedDelivered() {
            return this.filter.status == DELIVERY_statusDelivered;
        },
        selectedUndelivered() {
            return this.filter.status == DELIVERY_statusUndelivered;
        }
    },
    mounted() {
        this.getDeliveries();
        this.createNew();
    },
    methods: {
        goToSMS(number,message) {
            // if (!navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) { // add your own iOS check
            //     t = '?';
            // }
            var t = '?&body=';
            location.href = 'sms:'+ number + t + message;
            console.log('Number:', number, 'Message:',message);
        },
        goToCall(number) {
            location.href = 'tel:'+ number;
            console.log('Number:', number);
        },
        goDeliveryConfirm(id) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/admin/delivery_success/' + id ).then( (result) => {
                this.isLoading = false;
                this.$bvToast.toast(result.data.msg, {
                    title: 'Gotowe',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'success',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                });
                this.getDeliveries();
            }).catch( () => {
                this.isLoading = false;
                this.$bvToast.toast(`Błąd potwierdzania dostawy`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            });
        },
        getDeliveries(ctx){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            const promise = axios.post('/admin/deliveries', {
                "perPage": ctx ? ctx.perPage : this.perPage,
                "currentPage": ctx ? ctx.currentPage  : this.currentPage ,
                "sortBy": ctx ? ctx.sortBy : this.sortBy,
                "asc": ctx && ctx.sortDesc ? false : true,
                "filter": ctx ? ctx.filter : this.filter
            });

            return promise.then(result => {
                this.deliveries = result.data.deliveries;
                this.totalRows = result.data.count || 0;
                return this.deliveries || []
            })
        },
        setSelectedStatusNew() {
            this.filter.status = DELIVERY_statusNew;
            this.getDeliveries();
        },
        setSelectedStatusDelivered() {
            this.filter.status = DELIVERY_statusDelivered;
            this.getDeliveries();
        },
        setSelectedStatusUndelivered() {
            this.filter.status = DELIVERY_statusUndelivered;
            this.getDeliveries();
        },
        // myDataProvider(ctx) {
        //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
        //     const promise = axios.post('/admin/deliveries', {
        //         "perPage": ctx.perPage,
        //         "currentPage": ctx.currentPage,
        //         "sortBy": ctx.sortBy,
        //         "asc": ctx.sortDesc ? false : true,
        //         "filter": [],
        //         "status": this.filter.status
        //     });

        //     return promise.then(result => {
        //         const items = result.data.deliveries;
        //         this.totalRows = result.data.count || 0;
        //         return items || []
        //     })
        // },
        createNew() {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.post('/admin/deliveries/createnew', {}).then(result => {
                if(result.data.count > 0) {
                    this.getDeliveries();
                    this.$bvToast.toast("Utworzono " + result.data.count + " nowych dostaw.", {
                        title: 'Nowe dostawy',
                        autoHideDelay: 5000,
                        solid: true,
                        variant: 'success',
                        appendToast: true,
                        toaster: 'b-toaster-top-right'
                    })
                }
            }).catch( () => {
                    this.isLoading = false;
                    this.$bvToast.toast(`Błąd pobierania nowych dostaw.`, {
                        title: 'Błąd',
                        autoHideDelay: 5000,
                        solid: true,
                        variant: 'danger',
                        appendToast: true,
                        toaster: 'b-toaster-top-right'
                    })
                })
        }
    }
}
</script>
